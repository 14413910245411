import React from "react";
import { Helmet } from "react-helmet";
import Navigation from "../components/Layout/Navigation";
import DesktopMenu from "../components/Layout/Navigation/DesktopMenu";
import MenuControls from "../components/Layout/Navigation/MenuControls";
import MobileNavigation from "../components/Layout/Navigation/MobileNavigation";
import TopBar from "../components/Layout/TopBar";
import { css } from "@emotion/core";
import { graphql, Link, navigate, useStaticQuery } from "gatsby";
import tw from "tailwind.macro";
import GatsbyImage from "gatsby-image";
import { MdKeyboardArrowRight } from "react-icons/md";
import * as yup from "yup";
import Footer from "../components/Layout/Footer";
import FifthReusableTiles from "../components/FifthReusableTiles";
import hero from '../images/why-winalot-img1.png';
import hero2 from '../images/why-winalot-img2-2023.png';
import puppy from '../images/why-winalot-img6.png';
import adult from '../images/why-winalot-img3.png';
import senior from '../images/why-winalot-img5.png';
import small from '../images/why-winalot-img4-v2.png';
import SEO from "../components/seo";
import ReactPlayer from "react-player";
import { OutboundLink } from "gatsby-plugin-google-analytics";

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

export default function Competition() {
  const { image } = useStaticQuery(graphql`
    {
      image: file(relativePath: { regex: "/comp/hamper-main.png/" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
    {/* <SEO title="Why choose Winalot" keywords={[`great`, `british`, `dogs`]} /> */}
    <SEO
      title="greatbritishdogs.co.uk | Why choose Winalot"
      keywords={[`great`, `british`, `dogs`]}
    />
      <TopBar></TopBar>
      <Navigation>
        <MenuControls />
        <MobileNavigation></MobileNavigation>
      </Navigation>
      <DesktopMenu />

      <div>
        <div className="container">
          <div
            className="prose max-w-2xl mx-auto py-12"
            css={css`
              h1 {
                ${tw`text-primary font-slab font-bold text-center `}
              }
              h2 {
                ${tw`text-lightCopy text-primary font-sans font-bold   `}
              }
              h3 {
                ${tw`text-lightCopy text-prose text-center font-sans font-bold   `}
              }

              .accent {
                ${tw`text-accent`}
              }
              a {
                ${tw`text-primary no-underline font-bold `}
              }
            `}
          >
            <div className="text-center">
              <div className="font-bench  text-white text-xl mx-auto bg-accent px-4 py-1 uppercase leading-none inline-block rounded-lg my-3">
                Article
              </div>
            </div>
            <div className="form-title">
          <h1 css={css`
                color: #ae456c !important; margin: 0.5em auto 0.5em auto !important; font-size: 2rem !important; text-align: center !important; padding: 0 !important;
                `} className="font-slab">Why choose Winalot?</h1>

          <h2 className="font-sans font-bold text-xl text-primary" css={css`
                margin-bottom: 1em !important; margin-top: 1em !important; font-size: 1.1em !important;`}>
            {" "}
            SPONSORED BY WINALOT<sup>®</sup>
          </h2>
        </div>
            <div>
      <img src={hero} alt="Why Choose Winalot"/>
    </div>
    <p>Dogs have an unrivalled ability to make us laugh, keep us company and help us to stay active. Our furry companions truly are our everyday heroes, and naturally, you want to make sure yours is as happy and healthy as possible.</p>
    <p>That’s why for more than 90 years, Winalot ® has been feeding the greatness in dogs. Each of their products contain essential nutrients for your furry friend designed to help support healthy digestion, vitality and a shiny coat. It’s a formula that’s been trusted by generations, so you can rest assured it will work for your dog, too.</p>
    <img src={hero2} alt="Why Choose Winalot"/>
    <p>Winalot’s wet dog food pouches are clean, hygienic, and easy to feed with. You can choose to mix in with dry, or simply use them on their own. What’s more, the cardboard packaging is widely recyclable and Winalot has partnered with the Flexible Plastic Fund, so you can easily dispose of the plastic pouches sustainably.</p>
    <p>There are several Winalot ranges specially tailored to suit your dog’s stage of life or specific needs.</p>
<div className="text-left my-8">
<OutboundLink href="https://bit.ly/3eRimzY" target="_blank">

        {" "}
        <button className="bg-primary focus:outline-none text-white font-bench uppercase relative text-xl md:text-2xl py-2 px-16 rounded-full ">
          Discover More
          <MdKeyboardArrowRight
            className="w-8 h-8 absolute"
            css={css`
              top: calc(50% - 1rem);
              right: 1rem;
            `}
          />
        </button>
        </OutboundLink>
      </div>
<p>Which Winalot range is right for your dog? Read on to find out…</p>

            {/* <ReactPlayer
              url="/video/winalotstockVideo-no-cta.mp4"
              autoplay={true}
              controls
              width="100%"
              height="auto"
            /> */}

<Link className="font-bold font-slab" to="/adult">
            <h2 id="adult" css={css`margin-top: 1.2em; !important;`}>Adult</h2>
            </Link>
            <div>
            <img src={adult} alt="Adult"/>
            </div>
            <p>
            Winalot®’s adult range features a wide variety of flavours. Their succulent meaty chunks are made with natural ingredients*, without artificial flavours or preservatives and come in an easy to serve no mess pouch. So why not try their grain-free meaty chunks mixed in jelly today!
            </p>
            
            <div className="text-left my-8">
            <OutboundLink href="https://bit.ly/3eQheg6" target="_blank">

        {" "}
        <button className="bg-primary focus:outline-none text-white font-bench uppercase relative text-xl md:text-2xl py-2 px-16 rounded-full ">
        View product
          <MdKeyboardArrowRight
            className="w-8 h-8 absolute"
            css={css`
              top: calc(50% - 1rem);
              right: 1rem;
            `}
          />
        </button>
        </OutboundLink>
      </div>

      <Link className="font-bold font-slab" to="/small">
            <h2 id="small" css={css`margin-top: 1.2em; !important;`}>Small Dogs</h2>
            </Link>
            <div>
            <img src={small} alt="Small"/>
            </div>
            <p>
            Winalot® Small Dog has been specially designed for small dog breeds that weigh between 1-10kg. Developed by pet nutrition experts, these pouches contain flavoursome, bitesize pieces for small mouths that have been grilled to perfection!
            </p>

            <div className="text-left my-8">
            <OutboundLink href="https://bit.ly/3ndE0TL" target="_blank">

        {" "}
        <button className="bg-primary focus:outline-none text-white font-bench uppercase relative text-xl md:text-2xl py-2 px-16 rounded-full ">
        View product
          <MdKeyboardArrowRight
            className="w-8 h-8 absolute"
            css={css`
              top: calc(50% - 1rem);
              right: 1rem;
            `}
          />
        </button>
        </OutboundLink>
      </div>

      <Link className="font-bold font-slab" to="/puppy">
            <h2 id="puppy" css={css`margin-top: 1.2em; !important;`}>Puppy</h2>
            </Link>
            <div>
            <img src={puppy} alt="Puppy"/>
            </div>
            <p>
            A little extra care goes a long way. That’s why Winalot ® Puppy has been specially formulated to help support the health of your growing puppy. Every bit tasty as they are nutritious, these succulent meaty chunks are mixed in gravy and made with natural ingredients*.
            </p>
            

            <div className="text-left my-8">
            <OutboundLink href="https://bit.ly/32JayOF" target="_blank">

        {" "}
        <button className="bg-primary focus:outline-none text-white font-bench uppercase relative text-xl md:text-2xl py-2 px-16 rounded-full ">
        View product
          <MdKeyboardArrowRight
            className="w-8 h-8 absolute"
            css={css`
              top: calc(50% - 1rem);
              right: 1rem;
            `}
          />
        </button>
        </OutboundLink>
      </div>
            
            <Link className="font-bold font-slab" to="/senior">
            <h2 id="senior" css={css`margin-top: 1.2em; !important;`}>Senior Dogs (age 7+)</h2>
            </Link>
            <div>
            <img src={senior} alt="Senior"/>
            </div>
            <p>
            Maturing dogs have changing needs, which is why Winalot Senior (7+) has been specially formulated to help support the health of your older dog. Serve up wholesome, nutritious meals, made with natural ingredients* today!  
            </p>

            <div className="text-left my-8">
            <OutboundLink href="https://bit.ly/3zsqW1A" target="_blank">

        {" "}
        <button className="bg-primary focus:outline-none text-white font-bench uppercase relative text-xl md:text-2xl py-2 px-16 rounded-full ">
        View product
          <MdKeyboardArrowRight
            className="w-8 h-8 absolute"
            css={css`
              top: calc(50% - 1rem);
              right: 1rem;
            `}
          />
        </button>
        </OutboundLink>
      </div>

            <p>
            To discover the full range of Winalot products,  <a href="https://bit.ly/3eRimzY" target="_blank">click here </a>
            </p>
            <p>*Ingredients from natural origin</p>
            <p><br></br></p>
            
          </div>
          
        </div>
        
      </div>
      <div className="bg-pale-100">
        <div className="container" css={css`padding-right:0; padding-left:0;`}>
          <FifthReusableTiles />
        </div>
      </div>
      <Footer />
    </>
  );
}
